<template>
  <Template :headers="headers" @onAdd="() => isShow = true" :contacts="contacts" :pagination="{
    totalRecords: contacts?.length,
    currentPage: 1,
    perPage: 10,
  }"
    @onClickItem="(item) => $router.push({ name: 'Venture Contact Detail', params: { ventureId: this.venture?.id, id: item?.id }, query: { tab: 'Interviews' } })" />
  <WithNoSwiper v-bind="{
    isShow: isShow,
    isLoading: false,
    isSaving: isLoading,
    list: contactForm,
    modelKey: 'modelField',
    title: 'Add a new Interview',
    formData,
    content: 'Interviews are real people that fit your target customer definition.'
  }" :saveForm="saveForm" @onClose="() => { isShow = false }" />
</template>

<script>
import Template from '../components/templates/VentureInterviews/VentureInterviews'
import WithNoSwiper from "@/components/organisms/Modals/WithNoSwiper/WithNoSwiper";
import { contactForm } from "../../.storybook/sampleData";
import { convertEnumToRead } from '@/utils/utils.js';
import moment from 'moment';

export default {
  components: {
    WithNoSwiper,
    Template
  },
  data() {
    return {
      isShow: false,
      contactForm,
      isLoading: false,
      venture: null,
      contacts: [],
      formData: {},
      headers: [],
    }
  },
  methods: {
    async saveForm(data) {
      if (this.isLoading) return false;
      try {
        this.isLoading = true;
        let user = null;
        if (data.email) {
          const createAppUser = await this.actions.createAppUser({
            email: data?.email,
            sendEmailVerification: false
          });
          user = createAppUser?.user;
          if (!user?.id) {
            this.actions.alert.showError({ mesasge: 'User already exists' });
            return false;
          }
        }

        const saveUser = await this.actions.user.saveUser({
          where: user?.id ? { id: user.id } : null,
          data: {
            firstName: data?.firstName,
            lastName: data?.lastName,
            username: data?.firstName?.toLowerCase()
          }
        })
        user = saveUser;
        const params = {
          where: { id: this.$route.params?.ventureId },
          data: {
            contacts: {
              create: [{
                user: {
                  connect: { id: user?.id }
                },
                relationship: data?.relationship?.value,
                targetCustomerUser: {
                  connect: { id: data?.targetCustomer?.id }
                },
                status: 'NEW_CONTACT'
              }]
            }
          }
        }

        if (data?.platform?.name && data?.socialLink && data?.email) {
          params.data.contacts.create[0].socialHandles = {
            create: [{
              platform: data?.platform?.name,
              handle: data?.socialLink
            }]
          }
        } else if (data?.firstName) {
          params.data.contacts.create[0].socialHandles = {
            create: [{
              handle: data?.firstName
            }]
          }
        }

        await this.actions.venture.saveVenture(params);
        const ventures = await this.actions.venture.getVentures({
          where: { id: this.$route.params?.ventureId },
          getValues: true,
          all: true,
          query: 'ventureInterviews'
        });
        this.venture = ventures[0];
        this.onInitialize();
        this.isShow = false;
        this.actions.alert.showSuccess({ message: 'Created an interview successfully!' });
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false;
      }
    },
    onInitialize() {
      try {
        this.headers = [
          { label: 'Full Name', isSort: true, isAscending: true },
          { label: 'Target Customer', isFilter: true },
          { label: 'Email', isSort: true, isAscending: true },
          { label: 'Relationship', isFilter: true },
          { label: 'Status', isFilter: true },
          { label: 'Interview Date', isSort: true },
        ]
        this.contacts = [];
        this.venture?.contacts?.filter(o => !o?.isArchived)?.filter(c => c?.interviews?.find(interview => interview?.scheduledDateTime))?.map(p => {
          this.contacts.push({
            'Full Name': `${p?.user?.firstName || ''} ${p?.user?.lastName || ''}`,
            'Target Customer': p?.targetCustomerUser?.name,
            'Email': p?.user?.email,
            'Relationship': convertEnumToRead(p?.relationship),
            'Status': convertEnumToRead(p?.status),
            'Interview Date': p?.interviews?.[0]?.scheduledDateTime ? moment(p?.interviews?.[0]?.scheduledDateTime).format('M/DD @ h:mm') : '',
            'id': p?.id,
          })
        })
        console.log(this.contacts, 'contacts')
      } catch (e) {
        console.log(e)
      }

    }
  },
  async created() {
    const ventures = await this.actions.venture.getVentures({
      where: { id: this.$route.params?.ventureId },
      getValues: true,
      all: true,
      query: 'ventureInterviews'
    });
    this.venture = ventures[0];
    this.contactForm[0].fields[5].options = JSON.stringify(this.venture.targetCustomerUsers?.filter(t => !t?.isArchived) || []);
    if (this.venture.targetCustomerUsers?.filter(t => !t?.isArchived)?.length === 1) {
      this.formData.targetCustomer = this.venture.targetCustomerUsers?.filter(t => !t?.isArchived)[0]
    }
    this.onInitialize()
  }
}
</script>

<style scoped></style>
